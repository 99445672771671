<template>
  <div
    class="content video-content"
    :style="'min-height: ' + minHeight + 'px;'"
  >
    <div class="container">
      <div style="background-color: #fff; padding: 17px 20px">
        <el-page-header @back="goBack" content="课程视频"> </el-page-header>
      </div>
    </div>

    <section class="container course-info">
      <div>
        <div
          class="prism-player"
          id="J_prismPlayer"
          :style="'width: 100%; height: ' + videoHeight + 'px;'"
        ></div>
        <div class="play-setting" style="display:none;">
          <span class="speed">倍速 1.0X</span>
          <span class="definition active">高清</span>
          <span class="definition">标清</span>
        </div>
        <div class="info">
          <p class="title">{{ info.title }}</p>
          <div style="padding-top: 15px">
            <span class="num">{{ info.views }} 次播放</span>
          </div>
        </div>
      </div>
    </section>

    <section class="container course-list" style="padding-bottom: 0">
      <div class="course-list-header">详情</div>
    </section>
    <section class="container">
      <div class="desc">
        <div v-show="emptyShow" class="no-desc">
          <div>暂无详情</div>
        </div>
        <div v-html="info.content"></div>
      </div>
    </section>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import { videoInfo } from "../../api";
export default {
  data() {
    return {
      //player: null,
      videoHeight: 0,
      minHeight: window.innerHeight,
      contentWidth: window.innerWidth > 1080 ? 1080 : window.innerWidth,
      id: this.$route.query.id,
      info: {},
      emptyShow: false,
    };
  },
  mounted() {
    this.videoHeight = (this.contentWidth / 16) * 9;
    const self = this;
    self.getVideo();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getVideo() {
      const self = this;
      let data = {
        id: self.id,
      };
      videoInfo(data).then((res) => {
        //console.log(res);
        if (res.data.code != 0) {
          self.info = res.data;
          if (self.info.content && self.info.content <= 0) {
            self.emptyShow = true;
          }

          //player
          let player = new Aliplayer(
            {
              id: "J_prismPlayer",
              width: "100%",
              autoplay: true,
              isLive: false,
              autoPlayDelay: 5,
              autoPlayDelayDisplayText: "请等待...",
              vid: self.info.videovid, //必选参数。音视频ID。示例：1e067a2831b641db90d570b6480f****。
              playauth: self.info.palyAuth, //必选参数。音视频播放凭证。
            },
            function (player) {
              setTimeout(() => {
                player.play();
              }, 600);

              console.log("The player is created.");
            }
          );
          //player end
        }
      });
    },
  },
};
</script>
<style scoped>
.play-setting {
  background-color: #fff;
  padding: 5px 20px;
  text-align: right;
}

.play-setting .definition,
.play-setting .speed {
  font-size: 12px;
  color: #666;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 20px;
  border: 1px solid #eee;
  cursor: pointer;
  margin-left: 10px;
}

.play-setting .definition.active {
  background-color: #f2f3f0;
}

.course-info .info {
  background-color: #fff;
  font-size: 14px;
  padding: 10px 20px 20px;
  margin-top: -1px;
}

.course-info .info .title {
  font-size: 18px;
}

.course-info .num {
  color: #666;
}

.course-list {
  background-color: #fff;
  padding: 0 20px 50px;
  margin-top: 8px;
  font-size: 14px;
}

.course-list-header {
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  font-size: 18px;
  line-height: 60px;
  color: #555;
  margin-top: 8px;
}

.desc,
.no-desc {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  color: #888;
  font-size: 14px;
}

.no-desc div {
  padding: 100px 0;
  background-color: #fafafa;
}

.desc div {
  padding: 20px 0;
  background-color: #fafafa;
  min-height: 200px;
}
</style>